var UC;
(function (glob) {
  'use strict';
  glob.leftAd = {
    DEFAULT_WIDTH: 160,
    MIN_WIDTH: 100,
    LOG_RG: 'Left Ad',
    linkSelector: '.logo-left',
    closeBtnSelector: '.logo-left-close-btn',

    init: function () {
      this.imgUrl = $(this.linkSelector).attr('data-src');
      this.logName = $(this.linkSelector).attr('data-name');
      if (!this.isShow()) {
        return;
      }
      this.loadImage(this.getWidth());
      this.bindClose();
      this.bindLogging();
      this.bindResize();
    },

    getWidth: function () {
      var availWidth = ($(window).width() - $('#main').width()) / 2;
      if (availWidth > this.DEFAULT_WIDTH) {
        return this.DEFAULT_WIDTH;
      } else if (availWidth >= this.MIN_WIDTH) {
        return availWidth;
      }
      return 0;
    },

    loadImage: function (width) {
      $(this.linkSelector).append('<img src="' + this.imgUrl + '" style="width: ' + width + 'px"/>');
    },
    bindResize: function () {
      var self = this;
      var $img = $(self.linkSelector).find('img');
      $(window).on('resize', function () {
        if (self.isShow()) {
          $img.css('width', self.getWidth());
        }
      });
    },
    bindLogging: function () {
      var self = this;
      $('.logo-left').on('click', function () {
        UC.logWrite.write('click', {
          ck_rg: self.LOG_RG,
          ck_name: self.logName
        });
      });
    },
    bindClose: function () {
      var self = this;
      var imgUrl = self.imgUrl;
      $(this.closeBtnSelector).on('click', function (e) {
        e.preventDefault();
        $(this).parent().remove();
        UC.storage.item('leftAdClosed', imgUrl.substr(imgUrl.lastIndexOf('/') + 1));
        UC.logWrite.write('click', {
          ck_rg: self.logName,
          ck_name: 'Close Button'
        });
      });
    },
    isShow: function () {
      var oldImgName = UC.storage.item('leftAdClosed');
      if (!this.imgUrl) {
        return false;
      }
      if (oldImgName === null) {
        return true;
      }
      return this.imgUrl.substr(this.imgUrl.lastIndexOf('/') + 1) !== oldImgName
    }
  };
  glob.leftAd.init();
}(UC || (UC = {})));
