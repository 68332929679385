var UC;
(function (glob) {
  'use strict';
  glob.midBanner = {
    DEFAULT_WIDTH: 1024,
    DEFAULT_HEIGHT: 122,
    CK_RG: 'Middle Banner Ad',
    linkSelector: '#J_midBan',
    nextModule: '#J_hotSites',
    closeBtnSelector: '#J_midBanClsBtn',

    init: function (baseInfo) {
      this.url = baseInfo.url;
      this.imgUrl = baseInfo.img;
      this.ckName = baseInfo.ckName;
      if (!this.isShow()) {
        return;
      }
      this.loadContent();
      this.bindClose();
      this.bindLogging();
    },

    loadContent: function () {
      var self = this;
      var contentTpl = [
        '<div id="{{id}}" class="mid-banner hidden-element">',
        '<a href="{{url}}">',
        '<img src="{{imgUrl}}"/>',
        '<span id="{{closeBtnId}}" class="mid-banner-close-btn"></span>',
        '</a>',
        '</div>'
      ].join('');
      var contentHtml = UC.Template.format(contentTpl, {
        id: self.linkSelector.split('#')[1],
        url: self.url,
        imgUrl: self.imgUrl,
        closeBtnId: self.closeBtnSelector.split('#')[1]
      });
      $(contentHtml).insertBefore($(this.nextModule));
      $(self.linkSelector).find('img').on('load', function () {
        var $el = $(self.linkSelector);
        $(this).parent().css('height', self.DEFAULT_HEIGHT + 'px');
        $el
        .removeClass('hidden-element')
        .animate({
          height: self.DEFAULT_HEIGHT + 'px'
        }, 1000, 'linear');
      })
    },
    bindClose: function () {
      var self = this;
      var imgUrl = self.imgUrl;
      $(this.closeBtnSelector).on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(self.linkSelector)
        .removeClass('opening')
        .animate({
          height:0
        }, 500, 'linear', function () {
          $(self.linkSelector).remove();
        });
        UC.storage.item('midBannerClosed', imgUrl.substr(imgUrl.lastIndexOf('/') + 1));
        UC.logWrite.write('click', {
          ck_rg: self.CK_RG,
          ck_name: 'Close Button'
        });
      });
    },
    bindResize: function () {
      var self = this;
      var $img = $(self.linkSelector).find('img');
      $(window).on('resize', function () {
        if (self.isShow()) {
          $img.css('width', self.getWidth());
        }
      });
    },
    bindLogging: function () {
      var self = this;
      $(this.linkSelector).on('click', function () {
        UC.logWrite.write('click', {
          ck_rg: self.CK_RG,
          ck_name: self.ckName
        });
      });
    },
    isShow: function () {
      var oldImgName = UC.storage.item('midBannerClosed');
      if (!this.imgUrl) {
        return false;
      }
      if (oldImgName === null) {
        return true;
      }
      return this.imgUrl.substr(this.imgUrl.lastIndexOf('/') + 1) !== oldImgName
    }
  };
  if (window.midBannerInfo) {
    glob.midBanner.init(window.midBannerInfo);
  }
}(UC || (UC = {})));
