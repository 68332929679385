/**
 * logWrite
 * data:20151028
 * user: lulu
 */
var UC;
(function (UC) {
    var logWrite = (function () {
        function Log() {}
        Log.write = function (type, obj) {
            this._paq = window._paq || [];
            this._paramArr = window._paramArr || [];
            this._logParam = window._logParam || '';
            this.title = this._logParam.pageTitle || '';

            this._paq.push(['setSiteId', this._logParam.appid]);
            this._paq.push(['setDocumentTitle', this._logParam.pageTitle]);
            this._paq.push(['setTrackerUrl', logUrl + 'collect' + logSearch]);
            // this._paq.push(['trackPageView']);
            // this._paq.push(['enableLinkTracking']);

            this.delOldParam();
            // this.setSn();
            this.walog(type, obj);
            window._paq = this._paq;
            window._paramArr = this._paramArr;
            window._logParam.pageTitle = this.title;
            this.title = null;
        };
        /**删除前面link链接的信息，防止被重复打点 **/
        Log.delOldParam = function () {
            var params = this._paramArr;
            if (params) {
                for (var key in params) {
                    this._paq.push(['deleteCustomVariable', params[key], 'page']);
                }
            }
            this._paramArr = params;
        };
        /**
         * sn 给服务器发送浏览器性能数据
         * 浏览器接口window['feedback_sn'] 和 window['feedback_sn_new']
         * 页面回调接口 GetSn
         */
        Log.setSn = function () {
            var params = {
                sn: this._logParam.sn
            };
            this._paq.push(['setCustomVariable', 0, 'sn', params['sn'], 'page']);
        };
        /**
         * 设置自定义参数
         */
        Log.setVariable = function (key, value) {
            if (key && value) {
                this._paq.push(['setCustomVariable', 1, key, value, 'page']);
            }
        };
        //获取DOM节点上Ck数据
        //id_ck:50003; po_ck:1-2; name_ck:折800; pg_ck:UC123_PC-二级通用页PC-特卖; rg_ck:网址导航-特卖折扣
        Log.queryCk = function (str) {
            var args = {},
                items = str.split('; '),
                len = items.length,
                item = null,
                name = null,
                value = null;
            if (!str.length) {
                return null;
            }
            for (var i = 0; i < len; i++) {
                item = items[i].split('_ck:');
                name = item[0];
                value = item[1];
                name = ('id' === name || 'pg' === name) ? name : 'ck_' + name;
                args[name] = value;
            }
            return args;
        };
        Log.isDOM = (typeof HTMLElement === 'object') ?
            function (obj) {
                return obj instanceof HTMLElement;
            } :
            function (obj) {
                return obj && typeof obj === 'object' &&
                    obj.nodeType && obj.nodeType === 1 &&
                    typeof obj.nodeName === 'string';
            };
        /**
         * @param dom: 'this' // 可不填
         * @param type: 'view||click||search' //load 或 link 或 search
         * @param  pCk: '{}' //data, JSON格式
         */
        Log.walog = function (type, obj) {
            if ('view' === type) {
                /*页面加载打点*/
                this.setVariable('cs_remain', obj['cs_remain']);
                this._paq.push(['trackPageView']);
            } else if ('click' === type) {
                /*链接打点*/
                if (obj) {
                    var i = 0;
                    var oCk = null;
                    var dom = null;
                    if (this.isDOM(obj)) {
                        dom = obj;
                        var ck = dom.getAttribute('data-ck'); // $(dom).attr('data-ck');
                        try {
                            oCk = JSON.parse(ck);
                        } catch (e) {
                            oCk = this.queryCk(ck);
                        }
                    } else {
                        oCk = obj;
                    }

                    //打点字段上传
                    for (var key in oCk) {
                        var value = oCk[key];
                        if ('pg' === key) {
                            this._paq.push(['setDocumentTitle', value]);
                            continue;
                        }
                        i++;
                        this._paramArr.push(i);
                        this._paq.push(['setCustomVariable', i, key, value, 'page']);
                    }

                    //同域下的其他页面
                    var url = dom && dom.href ? dom.href : '';
                    var reg = /^(http:\/\/|https:\/\/)/;
                    if (url && !reg.test(url) && '#' !== url) {
                        var href = window.location.href;
                        var host = href.substring(0, href.lastIndexOf('/'));
                        url = host + '/' + url;
                    }

                    //手工采集某些连接的点击
                    this._paq.push(['trackLink', url || '#', 'link']);
                }
            } else if ('search' === type) {
                /*搜索打点,搜索外链了，不采集*/
                this._paq.push(['trackSiteSearch', dom.getAttribute('searchkey')]);
            }
        };
        return Log;
    })();
    UC.logWrite = logWrite;
})(UC || (UC = {}));

var _paq = _paq || [];
window.logSearch = location.search;
window.paramArr = [];
window.logUrl = '//gjtrack.uc.cn/';
window.logUaestUrl = (window.staticConfig && staticConfig.url) ? staticConfig.url + 'common/js/' : logUrl;

$(function () {
    //异步加载uaest.js
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.async = true;
    script.src = logUaestUrl + 'uaest.js';
    document.body.appendChild(script);

    //修改search参数
    // if (logSearch !== '') {
    //     logSearch = '?ch=' + logSearch.substring(logSearch.indexOf('f=')).split('&')[0].split('=')[1];
    // }

    //注册click事件
    window.setTimeout(function () {
        $(document).on('mousedown', '[data-track=true]', function (e) {
            e.stopPropagation();
            if (e.which === 1 || e.which === 2) {
                UC.logWrite.write('click', this);
            }
        });
    }, 25), $("#search_submit").click(function () {
        logWriteForm.trigger();
    });
});