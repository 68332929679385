(function (glob) {
  glob.searchSwitch = {
    searchList: [
      {
        name: 'bing',
        url: 'https://www.bing.com/search',
        param: 'q'
      },
      {
        name: 'google',
        url: 'https://www.google.co.in/search?hl=en-IN',
        param: 'q'
      },
      {
        name: 'yahoo',
        url: 'https://in.search.yahoo.com/search',
        param: 'p'
      }
    ],

    $banner: $('#J_searchBanner'),
    $switch: $('#J_searchSwitch'),
    $logo: $('#J_searchSwitch .search-logo-wrapper .search-logo'),
    $form: $('#J_searchForm'),
    $list: $('#J_searchList'),
    init: function () {
      this.load();
      this.bindSwitchClick();
      this.bindDocClick();
      this.bindSelect();
    },
    load: function () {
      var listTpl = [
        '<li data-name={{name}}>',
        '<span class="search-logo search-logo-{{name}}"></span>',
        '</li>'
      ].join('');
      var listContent = [];
      var currentEngine = this.$banner.attr('s-en');
      var self = this;
      $.each(this.searchList, function (index, obj) {
        if (obj.name !== currentEngine) {
          listContent.push(glob.Template.format(listTpl, {
            name: obj.name
          }));
        }
      });
      this.$list.append(listContent.join(''));
    },
    bindSwitchClick: function () {
      var self = this;
      $('#J_searchSwitch').on('click', '.search-logo-wrapper', function () {
        if (self.$switch.hasClass('show-list')) {
          self.hideBox();
        } else {
          self.showBox();
        }
      });
    },
    showBorder: function () {
      this.$switch.addClass('show-list');
    },
    hideBorder: function () {
      this.$switch.removeClass('show-list');
    },
    showBox: function () {
      this.$list.removeClass('hidden-element');
      this.$list.stop();
      this.$list.slideDown(100);
      this.showBorder();
      this.$switch.find('.search-arrow-icon').addClass('reversed');
    },
    hideBox: function () {
      var self = this;
      this.$list.stop();
      this.$list.slideUp(100, function () {
        self.hideBorder();
        self.$list.addClass('hidden-element');
      });
      this.$switch.find('.search-arrow-icon').removeClass('reversed');
    },
    bindSelect: function () {
      var self = this;
      this.$list.on('click', 'li', function () {
        var currentEngine = self.$banner.attr('s-en');
        var targetEngine = $(this).attr('data-name');
        var targetData = {};
        var i = self.searchList.length - 1;
        if (targetEngine !== currentEngine) {
          for (;i >= 0; i--) {
            if (self.searchList[i].name === targetEngine) {
              targetData = self.searchList[i];
            }
          }

          self.$banner
          .removeClass(currentEngine + '-search-banner')
          .addClass(targetEngine + '-search-banner')
          .attr('s-en', targetEngine);


          self.$logo
          .removeClass('search-logo-' + currentEngine)
          .addClass('search-logo-' + targetEngine);

          $(this).find('.search-logo')
          .removeClass('search-logo-' + targetEngine)
          .addClass('search-logo-' + currentEngine);

          $(this).attr('data-name', currentEngine);

          self.$form
          .attr('action', targetData.url);

          $('#J_searchKeyword').attr('name', targetData.param);

          glob.storage.item('selectedSearch', targetEngine);
        }
        self.$list.hide();
        self.hideBorder();
        self.$switch.find('.search-arrow-icon').removeClass('reversed');
      })
    },
    bindDocClick: function () {
      var self = this;
      $(document).bind("click", function(event){ // 防止blur和click事件冲突
        var el = $(event.target);
        if (!el.hasClass('search-logo')
          && el.closest('#J_searchSwitch').length == 0
          && el.closest('#J_searchList').length === 0) { // click事件在下拉框和logo位置以外
          self.hideBox();
        }
      });
    }
  };
  glob.searchSwitch.init();
}(UC || (UC = {})));