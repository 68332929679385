var UC;
(function(UC) {
  UC.searchLog = {
    data: {
      ck_rg: "search keyword",
      ck_po: "",
      ck_name: "",
      engine: ""
    },
    handle: function() {
      var searchInput = $("#J_searchKeyword"),
          searchStr = searchInput.val();
      this.data.ck_name = encodeURI(searchStr);
      this.data.engine = $('#J_searchBanner').attr('s-en');
      UC.logWrite.write("click", this.data);
    }
  };
})(UC || (UC = {}));

var UC;
(function(UC){
  UC.searchEngineOptions = {
    "google": {
      url: "//www.google.co.in/s?q=",
      data: {
          client:"psy-ab",
          hl: 'en-IN'
      },
      parseSugList: function(words) {
        var sugList = "";
        for (var i = 0; i < words.length; i++) {
          var annotation = "";
          var keyword = "", nameAttr = "";
          if (words[i].length >= 4 && words[i][3].b) {
            annotation = " - " +words[i][3].b;
            keyword = words[i][3].c;
          }
          if (keyword) {
            nameAttr = " name='"+keyword+"'";
          }
          sugList += "<li" + nameAttr + ">"+ words[i][0] + annotation + "</li>";
        }
        return sugList;
      },
      style: "google"
    }
  };
  UC.searchProcess = {
    bannerElement: $("#J_searchBanner"),
    sugElement: $("#J_searchSug"),
    inputElement: $("#J_searchKeyword"),
    formElement: $("#J_searchForm"),
    btnElement: $("#J_searchSubmit"),
    hoverClassName: "sug-hover",
    listIndex: 0, // 记录光标或鼠标指向的联想词序号
    lastSend: 0,  // 记录上次发送的ajax请求词，以免重复发送
    originalInput: "",  // 记录输入框内容，以供上下按键和ESC按键时恢复
    sugWordsNumber: 4, // 请求到的联想词条数
    focusedAfterSearch: true, // 搜索后，用户回到当前页，是否点击过搜索框（谷歌交互：首次点击需拉出联想词）
    init: function(engine) {
      var self = this;
      self.options = UC.searchEngineOptions[engine];
      self.bindKeyUp(self);
      self.bindKeyDown(self);
      self.bindBlur(self);
      self.bindFocus(self);
      self.bindHover(self);
      self.bindDocClick(self);
      self.btnElement.on("click", function(event){
        if (self.options.redirect && $.trim(self.inputElement.val()).length == 0) {
          event.preventDefault();
          window.open(self.options.redirect);
          return;
        }
        self.search();
      });
    },
    search: function(keyword){
      if (keyword) {
        this.inputElement.val(keyword);
      }
      /****
       一旦提交表单，跳转搜索页面，则当前页面的：
       1. 联想词列表清空，以便上下按键触发新的ajax请求（隐藏列表不会触发请求，上下按键还在原先的ajax结果上移动）
       2. 输入框内容记录为当前搜索词，以免ESC错乱
       3. 上次发送的请求词归零，否则请求词相同，导致无法发出ajax请求
       4. 联想词序号归零
       5. focusedAfterSearch（搜索后首次聚焦）置为false
      ****/
      this.clearSug();
      this.originalInput = this.inputElement.val();
      this.lastSend = "";
      this.listIndex = 0;
      this.focusedAfterSearch = false;
      UC.searchLog.handle();
      this.formElement.submit();
    },
    clearSug: function() {
      this.hideSug();
      this.sugElement.find("ul").remove();
    },
    hideSug: function() {
      this.sugElement.addClass("hidden-element");
    },
    showSug: function(){
      if(this.sugElement.children().length == 0) {
        return;
      }
      this.sugElement.removeClass("hidden-element");
    },
    getSug: function(self, object){
      var currentSend = object.val();
      if (currentSend == self.lastSend) { // 输入框内容与上次请求词相同
        return;
      }
      $.ajax({
        async: false, //同步方式
        dataType: "JSONP",
        type: "GET",
        url: self.options.url + currentSend,
        data: self.options.data,
        success:function(data){
          self.lastSend = currentSend;
          var words = (data && data.length>1) ? data[1]:[];
          if (!words || words.length==0) {  // 请求到的词为空，则删除列表并返回
            self.clearSug();
            return;
          }
          self.sugWordsNumber = words.length;  // 设置联想词总个数，确保上下按键行为正确
          var sugList = self.options.parseSugList(words);
          var sugHtml = "<ul>" + sugList + "</ul>";
          var s = self.sugElement;
          s.find("ul").remove();
          s.append(sugHtml);
          self.showSug();

          self.bindSugListEvent(self);
        }
      });
    },
    bindKeyUp: function(self){
      self.inputElement.on("keyup", function(event){ // 使用keyup绑定，长按上下箭头按键不会跳转
        if ($.trim($(this).val()).length == 0){
          self.lastSend = "";
          self.clearSug();
          return;
        }
        var key = event.which;

        if (key == 38) {
          /****
           向上按键：
           1. 如光标在输入框，则将输入框内容保存，以供恢复
           2. 如联想词显示，则序号减1；如联想词已隐藏，则序号不变（谷歌交互）
           3. 如序号溢出，则循环至队尾
          ****/
          if (self.listIndex == 0) {
            self.originalInput = $(this).val();
          }
          event.preventDefault();
          if(!self.sugElement.hasClass("hidden-element"))
            self.listIndex--;
          if (self.listIndex < 0){
            self.listIndex = self.sugWordsNumber;
          }
        } else if (key == 40) {
          /****
           向下按键：
           1. 如光标在输入框，则将输入框内容保存，以供恢复
           2. 如联想词显示，则序号加1；如联想词已隐藏，则序号不变（谷歌交互）
           3. 如序号溢出，则循环至队首
          ****/
          if (self.listIndex == 0) {
            self.originalInput = $(this).val();
          }
          if(!self.sugElement.hasClass("hidden-element"))
            self.listIndex++;
          if (self.listIndex > self.sugWordsNumber){
            self.listIndex = 0;
          }
        } else if(key ==27){
          /****
           ESC按键：
           1. 如光标在输入框，则将输入框内容保存，以供恢复
           2. 复位输入框（当光标不在输入框时会变化）
           3. 隐藏联想词
          ****/
          // event.preventDefault();
          if (self.listIndex == 0){
            self.originalInput = $(this).val();
          }
          $(this).val(self.originalInput);
          self.hideSug();
          return;
        } else {
          /****
           其他按键：
           1. 联想词列表序号归零
           2. 尝试发出ajax请求，如输入框内容与上次ajax请求词相同，则不会发出
          ****/
          self.listIndex = 0;
          self.getSug(self, $(this));
          return;
        }

        /****
         对上下按键的后处理
         1. 若联想词列表被清空：重新请求
         2. 联想词列表不为空：显示
         3. 光标移动
        ****/
        if (self.sugElement.children().length == 0) { // 联想词列表为空，重新请求
          self.listIndex = 0;
          self.getSug(self, $(this));
        } else {
        // if(self.sugElement.children().length > 0) { // 列表不为空，直接显示
          self.showSug();
        // } else {
        //   return;
        }
        if(self.listIndex!==0){ // 光标不在输入框，定位并高亮
          var li = self.sugElement.find("li:eq("+(self.listIndex-1)+")");
          $(this).val(li.attr("name")?li.attr("name"):li.text());
          li.addClass(self.hoverClassName).siblings().removeClass(self.hoverClassName);
        } else{ // 光标回到输入框，恢复刚才的输入
          $(this).val(self.originalInput);
          self.sugElement.find("li").removeClass(self.hoverClassName);
        }
      });
    },
    bindKeyDown: function(self){
      self.inputElement.on("keydown", function(event){ // 绑定按键按下动作
        if ($.trim($(this).val()).length <= 0){ // 长按Backspace键之后无需keyup即可清除联想词box
          self.clearSug();
          return;
        }
        var key = event.which;
        if(key == 38){ // 解决向上箭头按键使光标居左的问题
          event.preventDefault();
        } else if (key == 13) { // 搜索，初始化
          var focusedItem = self.sugElement.find("li:eq("+(self.listIndex-1)+")");
          var navItem = focusedItem.find("a");
          if (navItem.length > 0) {
            event.preventDefault();
            window.open(navItem.attr("href"));
            return;
          }
          self.search();
        }
        return;
      });
    },
    bindSugListEvent: function(self){
      self.sugElement.find("li").on("mouseover", function(){
        self.listIndex = $(this).index()+1;
        $(this).addClass(self.hoverClassName).siblings().removeClass(self.hoverClassName);
      }).on("click", function(){ // 搜索，初始化
        if($(this).find("a").length > 0) {  // 含a标签
          return;
        }
        var keyword = $(this).text();
        if ($(this).attr("name")) {
          keyword = $(this).attr("name");
        }
        self.search(keyword);
      });
    },
    bindBlur: function(self){
      self.inputElement.on("blur", function(){
        $(this).removeClass("input-focus");
      });
    },
    bindFocus: function(self){
      self.inputElement.on("focus", function(){
        $(this).addClass("input-focus");
        // 当用户搜索后回到首页，第一次点击搜索框的时候主动请求/显示联想词；其后再点击则不予处理
        if (!self.focusedAfterSearch && $.trim($(this).val()).length > 0){
          self.getSug(self, $(this));
          self.focusedAfterSearch = true;
        }
      });
    },
    bindHover: function(self){
      self.inputElement.on("mouseover", function(){
        $(this).addClass("input-hover");
      }).on("mouseout", function(){
        $(this).removeClass("input-hover");
      });
    },
    bindDocClick: function(self){
      $(document).bind("click", function(event){ // 防止blur和click事件冲突
        var el = $(event.target);
        if (!el.hasClass(self.hoverClassName) && el.closest(self.inputElement).length==0) { // click事件在联想词和输入框以外
          self.hideSug();
        }
      });
    }
  }
  UC.searchProcess.init('google');
})(UC || (UC = {}));
