var UC;
(function (glob) {
  'use strict';
  glob.background = {
    PIC_SIZE: 138,
    MIN_PIC_SIZE: 90,
    MIN_SIDE_WIDTH: 70,
    HIDDEN_WIDTH: 104,
    LOG_NAME: 'Background Logos',

    init: function (info) {
      this.bgInfo = info;
      if (!this.isShow()) {
        return;
      }
      this.width = this.getWidth();
      this.loadContent(this.width, this.getRows());
      this.setStyle();
      this.bindClose();
      this.bindLogging();
      this.bindResize();
    },
    getRows: function () {
      var visibleHeight = $('#main').height();
      // $(window).height();
      if (visibleHeight % this.PIC_SIZE >= this.MIN_PIC_SIZE) {
        return Math.ceil(visibleHeight / this.PIC_SIZE);
      }
      return Math.floor(visibleHeight / this.PIC_SIZE);
    },
    getColumns: function (visibleWidth) {
      var columns = visibleWidth / this.PIC_SIZE;
      if (visibleWidth <= this.MIN_SIDE_WIDTH) {
        return 0;
      }
      return Math.floor(columns) > 0 ? Math.floor(columns) : 1;
    },
    getWidth: function () {
      return ($(window).width() - $('#main').width()) / 2;
    },
    loadContent: function (width, rows) {
      var self = this;
      var evenRowContent = [];
      var oddRowContent = [];
      var content = [];

      var leftNode = $('<div class="bg-wrapper bg-left" style="width: ' + width + 'px"></div>');
      var rightNode = $('<div class="bg-wrapper bg-right" style="width: ' + width + 'px"></div>');
      var columns = this.getColumns(width);
      var minEvenWidth = (columns + 1) * self.PIC_SIZE;
      var designEvenWidth = width + self.HIDDEN_WIDTH;
      var evenWidth = designEvenWidth > minEvenWidth ? designEvenWidth : minEvenWidth;
      var i;
      if (!columns) {
        $('.bg-wrapper').remove();
        return;
      }
      for (i = 1; i <= columns + 1; i++) {
        evenRowContent.push(
          UC.Template.format('<a href="{{url}}" class="{{bgClass}}"></a>', {
            url: self.bgInfo.url,
            bgClass: i % 2 ? 'bg-link-odd' : 'bg-link-even'
          })
        );
      }
      for (i = 1; i <= columns; i++) {
        oddRowContent.push(
          UC.Template.format('<a href="{{url}}" class="{{bgClass}}"></a>', {
            url: self.bgInfo.url,
            bgClass: i % 2 ? 'bg-link-odd' : 'bg-link-even'
          })
        );
      }
      for (i = 1; i <= rows; i++) {
        content.push(
          UC.Template.format('<div class="{{rowClass}} bg-row" style="width:{{width}}px;">{{rowContent}}</div>', {
            rowClass: i % 2 ? 'odd-row' : 'even-row',
            width: i % 2 ? width : evenWidth,
            rowContent: i % 2 ? oddRowContent.join('') : evenRowContent.join('')
          })
        );
      }
      leftNode.append(content.join('')).css('left', '-' + width + 'px');
      rightNode.append(content.join('')).css('right', '-' + width + 'px');

      // 关闭按钮
      if (!$('#close_background').length) {
        $('#J_header').append('<div id="close_background" class="sprite-icon"></div>');
      }
      $('#close_background').css('right', '-' + (width - 8) + 'px');

      // 没有配置URL时（电商弹窗）隐藏元素
      if (!self.bgInfo.url) {
        leftNode.addClass('hidden-element');
        rightNode.addClass('hidden-element');
        $('#close_background').addClass('hidden-element');
      }

      $('.bg-wrapper').remove();
      $('#J_header')
      .append(leftNode)
      .append(rightNode);


    },
    setStyle: function () {
      var self = this;
      // Set background image for all links
      $('head').append(UC.Template.format([
        '<style>',
        // '.bg-left{left:-{{width}}px;}',
        // '.bg-right{right:-{{width}}px;}',
        '.bg-link-odd{background-image:url({{img1}});}',
        '.bg-link-odd:hover{background-image:url({{litImg1}});}',
        '.bg-link-even{background-image:url({{img2}});}',
        '.bg-link-even:hover{background-image:url({{litImg2}});}',
        '.bg-hidden{background-image:none;}',
        '.bg-hidden:hover{background-image:none;}',
        '</style>'
      ].join(''), {
        width: self.width,
        img1: self.bgInfo.img1,
        litImg1: self.bgInfo.litImg1,
        img2: self.bgInfo.img2,
        litImg2: self.bgInfo.litImg2
      }));
    },
    bindResize: function () {
      var self = this;
      $(window).on('resize', function () {
        if (self.isShow()) {
          self.loadContent(self.getWidth(), self.getRows());
        }
      });
    },
    bindLogging: function () {
      var self = this;
      $('#J_header').on('click', '.bg-wrapper a', function () {
        UC.logWrite.write('click', {
          ck_rg: self.LOG_NAME,
          ck_name: self.LOG_NAME
        });
      });
    },
    bindClose: function () {
      var self = this;
      var imgUrl = this.bgInfo.img1;
      $('#close_background').on('click', function () {
        $('.bg-wrapper').remove();
        $(this).remove();
        UC.storage.item('bgClosed', imgUrl.substr(imgUrl.lastIndexOf('/') + 1));
        UC.logWrite.write('click', {
          ck_rg: self.LOG_NAME,
          ck_name: 'Close Button'
        });
      });
    },
    isShow: function () {
      var oldImgName = UC.storage.item('bgClosed');
      var imgUrl = this.bgInfo.img1;
      if (oldImgName === null) {
        return true;
      }
      return imgUrl.substr(imgUrl.lastIndexOf('/') + 1) !== oldImgName
    }
  };
  if (window.bgInfo) {
    glob.background.init(window.bgInfo);
  }
}(UC || (UC = {})));
