var UC;
(function(glob){
  glob.mailList = {
    $list: $("#J_mailList"),
    $box: $('.mail-selector .border-line-box'),
    $selector: $('.mail-selector'),

    lsKey: "selectedMail",
    itemHeight: 58,
    dataRg: 'Email Selector',

    itemTemplate: [
      '<li>',
      '<a class="mail-item" title="{{name}}" href="{{url}}">',
      '<img src="{{imgUrl}}"/>',
      '<span>{{name}}</span>',
      '</a>',
      '</li>'
    ].join(""),
    init: function(data){
      var self = this;
      self.data = data;
      self.setBoxHeight(data);

      this.bindDropBox();
      this.bindDocClick();
    },
    setBoxHeight: function (data) {
      this.listHeight = this.itemHeight * (data.length - 1);
    },
    bindDropBox: function () {
      var self = this;
      $('.mail-arrow').on('click', function () {
        if (self.$box.hasClass('paint-border')) {
          self.hideList();
        } else {
          if(!self.$list.children().length){
            self.loadList();
          }
          self.showList();
        }
      });
      $('.mail-content').on('click', '.mail-item', function () {
        self.hideList();
        glob.logWrite.write('click', {
          ck_rg: self.dataRg,
          ck_name: $(this).text()
        });
      });
    },

    showBorder: function () {
      this.$box.addClass('paint-border');
    },
    hideBorder: function () {
      this.$box.removeClass('paint-border');
    },
    loadList: function(){
      var mailList = [];
      var $selectedItem = $('.mail-content .mail-item');
      var selectedName = "";
      var self = this;
      if ($selectedItem.length) {
        selectedName = $selectedItem.attr("title");
      }
      for(var i=0, len=this.data.length;i<len;i++){
        var item=this.data[i];
        if(item.name == selectedName){
          continue;
        }
        mailList.push(UC.Template.format(this.itemTemplate, {
          name: item.name,
          url: item.url,
          imgUrl: item.imgUrl
        }));
      }
      this.$list.append(mailList.join(''));
      $(".mail-list").on("click", ".mail-item", function(){
        self.selectListItem($(this));
      });
    },
    setDefaultItem: function($item){
      var $selector = this.$selector;
      var $wrapper = $selector.find(".mail-content");
      var $oldItem = $wrapper.find(".mail-item");
      var $targetLi = $item.parent();

      $oldItem.appendTo($targetLi);

      $item.appendTo($wrapper);
    },
    selectListItem: function(item){
      var itemData = {
        name: item.attr("title"),
        url: item.attr("href"),
        imgUrl: item.children("img").attr("src")
      };
      UC.logWrite.write("click", {
        ck_rg: this.dataRg,
        ck_name: itemData.name + ' selected'
      });
      this.hideList();
      this.setDefaultItem(item);
      ls && ls.item(this.lsKey, JSON.stringify(itemData));
    },
    showList: function(){
      var self = this;
      this.showBorder();
      this.$list.removeClass('hidden-element');
      this.$list.stop();
      this.$list.animate({
        height: self.listHeight
      }, 200);
      this.$selector.find('.mail-arrow-icon').addClass('reversed');
    },
    hideList: function(){
      var self = this;
      this.$list.stop();
      this.$list.animate({
        height: 0
      }, 200, function () {
        self.hideBorder();
        self.$list.addClass('hidden-element');
      });
      this.$selector.find('.mail-arrow-icon').removeClass('reversed');
    },
    bindDocClick: function () {
      var self = this;
      $(document.body).on('click', function(event) {
        var $el = $(event.target);
        if ($el.closest('.mail-selector').length == 0) {
          self.hideList();
        }
      });
    }

  };
})(UC || (UC={}));
UC.mailList.init(window.mailData||{});
