(function (glob) {
  'use strict';
  var host = window.location.host;
  var version = window.staticConfig.shopVer;
  var sourceHost = '';
  var script = document.createElement('script');
  if (host.indexOf('daily.taobao.net') !== -1) {
    sourceHost = 'g-assets.daily.taobao.net';
  } else {
    sourceHost = 'g.alicdn.com';
  }

  script.type = 'text/javascript';
  script.src = '//' + sourceHost + '/browser/i18n-ebusiness-uc123/' + version + '/index.js';
  script.crossOrigin = 'anonymous';
  if (version) {
    document.body.appendChild(script);
  }
}(UC || (UC = {})));

