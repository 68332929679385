var UC;
(function (glob) {
  glob.coolSite = {
    $parent: $('#J_coolSites'),
    load: function () {
      this.next(this.$parent.children().first());
    },
    next: function ($node) {
      if (!$node.hasClass('coolsite')) {
        return;
      }
      $node.append('<img class="coolsite-logo" src="' + $node.attr('data-src') + '" style="display:none" />');
      $node.find('.coolsite-logo').fadeIn(1000);
      this.next($node.next());
    }
  };
  glob.coolSite.load();
}(UC || (UC = {})));
