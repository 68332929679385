var UC;
(function (glob) {
  glob.hotSite = {
    $wrapper: $('#J_hotSites'),
    $custom: $('#J_customSites'),
    $default: $('#J_defaultSites'),
    $slider: $('.hotsite-slider'),
    storageKey: 'customSites',
    dataRg: 'Custom Sites',
    tipsImg: '//gtd.alicdn.com/tfscom/TB1qrw8OVXXXXXIXXXXwu0bFXXX',
    liTpl: [
      '<li>',
      '<a class="hotsite" href="{{url}}" title="{{name}}">',
      '<span>{{name}}</span>',
      '<i class="hs-delete"></i>',
      '</a>',
      '</li>'
    ].join(''),
    listLength: 0,
    init: function () {
      this.loadCustomContent();
      this.bindEditClick();
      this.bindTabClick();
      this.bindEditModeExits();
      this.bindLog();
    },
    slideLeft: function () {
      var self = this;
      this.$custom.css('visibility', 'visible');
      this.$slider.stop().animate({
        marginLeft: -780
      }, function () {
        self.$default.css('visibility', 'hidden');
        self.$wrapper.css('overflow', 'visible');
      });
    },
    slideRight: function () {
      var self = this;
      this.$wrapper.css('overflow', 'hidden');
      this.$default.css('visibility', 'visible');
      this.$slider.stop().animate({
        marginLeft: 0
      }, function () {
        self.$custom.css('visibility', 'hidden');
      });
    },
    bindTabClick: function () {
      var self = this;
      $('#J_switchTabs div').on('click', function () {
        var dataType = $(this).attr('data-tb');
        var targetSelector = '#J_' + dataType + 'Sites'; // J_defaultSites and J_customSites

        if ($(this).hasClass('inactive')) {
          $(this).removeClass('inactive');
          $(this).siblings().addClass('inactive');

          if (dataType === 'default') { // 向右滑动
            self.slideRight();
          } else {  // 向左滑动
            self.slideLeft();
            if (self.listLength === 0 && $('.tips-img').length === 0) {
              self.loadTips();
              self.showTips();
            }
          }
          glob.logWrite.write('click', {
            ck_rg: self.dataRg,
            ck_name: 'Switch to ' + dataType
          });
        }
      });
    },
    loadCustomContent: function () {
      var itemTpl = this.liTpl;
      var customContent = [];
      var customList = [];
      var customStr = glob.storage.item(this.storageKey);
      $('#J_customSites').append('<i id="J_customEdit" class="hs-edit-btn"></i>');
      if (customStr) {
        try {
          customList = JSON.parse(customStr);
          this.listLength = customList.length;
          if (this.listLength > 0) {
            $.each(customList, function (index, site) {
              customContent.push(glob.Template.format(itemTpl, {
                url: site.url,
                name: site.name
              }));
            });
            $('#J_customSites ul').append(customContent.join(''));
            window.cs_remain = 1;
          }
        } catch (e) {
        }
      }
      // this.loadTips();
      // this.showTips();
    },
    disableLink: function () {
      $('#J_customEdit').on('click', 'hotsite', function (event) {
        event.preventDefault();
      });
    },
    bindEditClick: function () {
      var self = this;
      this.$custom.on('click', '#J_customEdit', function () {
        self.enterEditMode();
      });
    },
    loadEditPanel: function () {
      var panelHtml = [
        '<div id="J_customEditPanel" class="hs-edit-panel">',
        '<i class="hs-edit-fold"></i>',
        '<p>Please enter the information of the site you want to add</p>',
        '<div class="hs-input-wrapper">',
        '<span class="hs-input-label">Name</span>',
        '<input autocomplete="off" name="name"/>',
        '<span class="hs-input-label">URL</span>',
        '<input autocomplete="off" name="url"/>',
        '<span class="hs-add-btn">ADD</span>',
        '</div>',
        '<p><i class="hs-info-icon"></i>',
        'The sites you add are stored in local storage. ',
        'If you clear browser cookies, the added sites will also be deleted.',
        '</p>',
        '</div>'
      ].join('');
      this.$custom.append(panelHtml);

    },
    saveList: function () {
      var customList = [];
      $('#J_customSites .hotsite').each(function() {
        customList.push({ name: $(this).text(), url: $(this).attr('href') });
      });
      glob.storage.item(this.storageKey, JSON.stringify(customList));
    },
    bindDrag: function () {
      var self = this;
      $('#J_customSites.edit-mode ul').dragsort({
        dragSelector: 'li',
        dragSelectorExclude: '.hs-delete',
        placeHolderTemplate: '<li><span class="hs-drag-holder"></span></li>',
        dragEnd: function () {  // 拖动完成后的回调
          self.saveList();
        }
      });
    },
    enterEditMode: function () {
      var $editPanel = $('#J_customEditPanel');
      this.$custom.addClass('edit-mode');
      if (!$editPanel.length) {
        this.loadEditPanel();
        this.bindAddSite();
        this.bindDeleteSite();
      }
      this.hideTips();

      $('#J_customEditPanel').addClass('show').removeClass('hide');
      this.bindDrag();
      this.disableLink();
    },
    exitEditMode: function () {
      this.$custom.removeClass('edit-mode');
      // 处理占位图的加载和显示
      if (this.listLength === 0) {
        if (!this.$custom.find('.tips-img').length) {
          this.loadTips();
        }
        this.showTips();
      } else {
        this.hideTips();
      }

      $('#J_customEditPanel').addClass('hide').removeClass('show');
      $('#J_customSites ul').dragsort('destroy');
      $('#J_customEditPanel input').val('');
    },
    bindEditModeExits: function () {
      var self = this;
      $(document.body).on('click', function(event) {
        var $el = $(event.target);
        if ($('#J_customSites').hasClass('edit-mode') && $el.closest('#J_customSites').length == 0) { // click事件在自定义网址区域以外
          self.exitEditMode();
        }
      });
      this.$custom.on('click', '.hs-edit-fold', function () {
        self.exitEditMode();
      });
    },
    showWarning: function (info) {
      $('.hs-input-warning').remove();
      $('.hs-input-wrapper').append('<p class="hs-input-warning" style="display: none;">' + info + '</p>');
      $('.hs-input-warning').fadeIn();
      window.setTimeout(function () {
        $('.hs-input-warning').fadeOut('slow', function () {
          // $('.hs-input-warning').remove();
        });
      }, 2000);
    },
    loadTips: function () {
      this.$custom.append('<img class="tips-img" src="' + this.tipsImg + '" />');
    },
    showTips: function () {
      var tipsImg = this.$custom.find('.tips-img');
      tipsImg.fadeIn(600);
    },
    hideTips: function () {
      this.$custom.find('.tips-img').hide();
    },
    bindAddSite: function () {
      var self = this;
      var warnings = {
        empty: 'Site name and URL can not be empty.',
        illegal: 'Not a valid URL.',
        overflow: 'No room left to add a site.You can delete a site(first) before adding a new one.',
        tooLong: 'Please keep the site name within 30 characters.'
      };
      this.$custom.on('click', '.hs-add-btn', function () {
        var name = $(this).siblings('input[name="name"]').val();
        var url = $(this).siblings('input[name="url"]').val();
        var firstRegex = '^((https|http|ftp|rtsp|mms)?://)';
        var secondRegex = "^((https|http|ftp|rtsp|mms)?://)"
          + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftp的user@
          + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
          + "|" // 允许IP和DOMAIN（域名）
          + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
          + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
          + "[a-z]{2,6})" // first level domain- .com or .museum
          + "(:[0-9]{1,4})?" // 端口- :80
          + "((/?)|" // a slash isn't required if there is no file name
          + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
        if (self.listLength < 24) { // 网址空余位置检查
          if (name && url) {  // 填写字段非空检查
            if (name.length <= 30) { // 网站名称长度检查
              if (!RegExp(firstRegex).test(url)) {  // 补全URL
                url = 'http://' + url;
              }
              if (RegExp(secondRegex).test(url)) {  // URL合法性检查
                self.$custom.children('ul').append(glob.Template.format(self.liTpl, {
                  name: name,
                  url: url
                }));
                $(this).siblings('input').val('');
                self.listLength++;
                self.saveList();
                glob.logWrite.write('click', {
                  ck_rg: self.dataRg,
                  ck_name: 'Add',
                  custom_url: url,
                  custom_name: name
                });
              } else {
                self.showWarning(warnings.illegal);
              }
            } else {
              self.showWarning(warnings.tooLong);
            }
          } else {
            self.showWarning(warnings.empty);
          }
        } else {
          self.showWarning(warnings.overflow);
        }
      });
    },
    bindDeleteSite: function () {
      var self = this;
      this.$custom.on('click', '.hs-delete', function (event) {
        event.preventDefault();
        event.stopPropagation();
        $(this).closest('li').remove();
        self.listLength--;
        self.saveList();
      });
    },
    bindLog: function () {
      var self = this;
      this.$custom.on('click', '.hotsite', function () {
        glob.logWrite.write('click', {
          ck_rg: self.dataRg,
          ck_name: $(this).text()
        });
      });
    }
  };
  glob.hotSite.init();
}(UC || (UC = {})));
